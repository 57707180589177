<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛事: ">
                    <Row>
                        <Col span="20" >
                            <uniqueTournamentSearch v-model="data.unique_tournament" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'unique_tournament_id'] === 1? 'locked-input': '' "></uniqueTournamentSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'unique_tournament_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="unique_tournament_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="赛季: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season_id" :sport_id="sport_id" :unique_tournament_id="data.unique_tournament.id" :classname="locks[String(type)+'-1-'+'season_id'] === 1? 'locked-input': '' "></seasonSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'season_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="season_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="荣誉年份: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.season" :class="locks[String(type)+'-1-'+'season'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'season']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="season"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="选手: ">
                    <Row>
                        <Col span="20" >
                            <playerSearch v-model="data.player" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'player'] === 1? 'locked-input': '' "></playerSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'player']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="player"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="荣誉: ">
                    <Row>
                        <Col span="20" >
                            <honorSearch v-model="data.honor" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'player'] === 1? 'locked-input': '' "></honorSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'honor']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="honor"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="球队: ">
                    <Row>
                        <Col span="20" >
                            <teamSearch v-model="data.team" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'team'] === 1? 'locked-input': '' "></teamSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'honor']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="team"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="国家: ">
                    <Row>
                        <Col span="20" >
                            <countrySearch :sport_id="sport_id" v-model="data.country" :class="locks[String(type)+'-1-'+'country_id'] === 1? 'locked-input': '' " ></countrySearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="country_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="player_honor_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import uniqueTournamentSearch from '../unique_tournament/search.vue';
import seasonSearch from '../season/search.vue';
import countrySearch from "../country/search.vue";
import playerSearch from '../player/search.vue';
import honorSearch from '../honor/search.vue';
import Lock from '../lock.vue';
import { getPlayerHonor, updatePlayerHonor} from '@/api/ballsports/base/player_honor';
import teamSearch from "../team/search.vue";

export default {
    name: "playerHonorEdit",
    props: {
        sport_id: Number,   // 运动id
        player_honor_id: Number,   // 联赛id
    },
    components: {
        Lock,
        countrySearch,
        teamSearch,
        uniqueTournamentSearch,
        seasonSearch,
        playerSearch,
        honorSearch,
        // imgEditor,
        // venueTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 7,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                name:'',
                country: {},
                unique_tournament: {},
                season_id: {},
                team: {},
                player: {},
                honor: {},
                deleted: 0,
            },
            locks: {},
            new_team: {
                "id": 0,
                "name": '',
                "logo": '',
            },
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.player_honor_id && self.player_honor_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.player_honor_id,
                }
                getPlayerHonor( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // self.locks = response.data.data.locks ;
                            self.locks = {}
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.locks = {}
                self.data = {
                    id: 0,
                    sport_id: self.sport_id,
                    country: {},
                    unique_tournament: {},
                    season_id: {},
                    team: {},
                    player: {},
                    honor: {},
                    deleted: 0,
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            let home_team_ids = []
            if (self.data.home_teams && self.data.home_teams.length > 0){
                self.data.home_teams.forEach(element => {
                    home_team_ids.push(element.id)
                });
            }

            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                honor_id: self.data.honor.id,
                player_id: self.data.player.id,
                team_id: self.data.team.id,
                country_id: self.data.country.id,
                season: self.data.season,
                comp_id: self.data.unique_tournament.id,
                season_id: self.data.season_id.id,
                deleted: self.data.deleted,
            }
            updatePlayerHonor(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "player_honor_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>