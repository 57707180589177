import http from '@/utils/http';

// // 搜索
// export const searchVenue = (params) => {
//     return http.request({
//         url: '/v1/ballsports/base/venue/search',
//         method: 'post',
//         data: params
//     })
// }

// 获取列表
export const getPlayerHonor = (params) => {
    return http.request({
        url: '/v1/ballsports/base/player_honor/list',
        method: 'get',
        params: params
    })
}

// 更新
export const updatePlayerHonor = (params) => {
    return http.request({
        url: "/v1/ballsports/base/player_honor/update",
        method: 'post',
        data: params,
    })
}